import {Component, OnInit} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {forkJoin} from 'rxjs';
import {MarkdownService} from 'ngx-markdown';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  // tree model
  treeNodes: TreeNode[] = [];

  // data
  directories: any = [];
  helps: any = [];

  // user actions
  selectedNode: TreeNode = null;
  helpName: any;
  helpId: any;
  helpContent: any;

  constructor(
    private http: HttpClient,
    private markdownService: MarkdownService) {}

  ngOnInit(): void {

    // load directories and helps
    forkJoin([
      this.loadHelps(),
      this.loadDirectories()
    ]).subscribe(dataArrays => {

      // save data
      this.helps = dataArrays[0];
      this.directories = dataArrays[1];

      // process received directories to tree structure
      this.treeNodes = this.recursivelyProcessDirectory(null);

      // log output
      // console.log('Finals');
      // console.log(this.treeNodes);
      // console.log(this.helps);
    });
  }

  loadDirectories(): any{
    return this.http.get(environment.helpUrl + '/directories'); /*.subscribe((directories: any[]) => {
      this.directories = directories;
    });*/
  }

  loadHelps(): any{
    return this.http.get(environment.helpUrl + '/helps'); /*.subscribe((helps: any[]) => {
      this.helps = helps;
    });*/
  }

  recursivelyProcessDirectory(parentId: any): TreeNode[]{
    // console.log('Recursing children of: ' + parentId);

    // filter directories with certain parent
    const childrenDirectories: any[] = this.directories.filter(dir => {
      if (dir.parent === null && parentId === null) { return true; } // null parent match - top level
      if (dir.parent !== null && parentId === dir.parent.id) {return true; } // exact id match
      return false; // no match
    });

    // find helps
    const childrenHelps: any[] = this.helps.filter(help => {
      if (help.parent === null && parentId === null) { return true; } // null parent match - top level
      if (help.parent !== null && parentId === help.parent.id) {return true; } // exact id match
      return false; // no match
    });

    // console.log(childrenDirectories);
    if ((childrenDirectories === null || childrenDirectories.length === 0) && (childrenHelps === null || childrenHelps.length === 0)) {
      // console.log('No children, exiting');
      return null; // no children - return null
    }

    // add all children
    const childrenTree: TreeNode[] = [];
    childrenDirectories.forEach(dir => {
      childrenTree.push({
        label: dir.name,
        data: dir,
        expandedIcon: 'pi pi-folder-open',
        collapsedIcon: 'pi pi-folder',
        selectable: false,
        children: this.recursivelyProcessDirectory(dir.id)
      });
    });

    // add all helps
    childrenHelps.forEach(help => {
      childrenTree.push({
        label: (help.MenuLabel === null ? help.Name : help.MenuLabel),
        data: help,
        expandedIcon: 'pi pi-question',
        collapsedIcon: 'pi pi-question'
      });
    });

    // return
    return childrenTree;
  }

  nodeSelect(event: any): void{
    // console.log(event);
    this.helpName = event.node.data.Name;
    this.helpId = event.node.data.id;
    this.helpContent = this.markdownService.compile(event.node.data.Content);
  }

  nodeUnselect(event: any): void{
    // console.log(event);
  }

  expandAll(): void{
    this.treeNodes.forEach( node => {
      this.expandRecursive(node, true);
    } );
  }

  collapseAll(): void{
    this.treeNodes.forEach( node => {
      this.expandRecursive(node, false);
    } );
  }

  private expandRecursive(node: TreeNode, isExpand: boolean): void{
    node.expanded = isExpand;
    if (node.children){
      node.children.forEach( childNode => {
        this.expandRecursive(childNode, isExpand);
      } );
    }
  }
}
