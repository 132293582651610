<h3>Bytové konto - prohlížeč nápovědy</h3>
<div class="p-grid">
  <div class="p-col-4">
    <div style="margin-bottom: 1rem">
      <button pButton type="button" label="Otevřít vše" (click)="expandAll()" style="margin-right: .5rem" class="p-button-sm"></button>
      <button pButton type="button" label="Zavřít vše" (click)="collapseAll()" class="p-button-sm"></button>
    </div>
  </div>
  <div class="p-col-8">
  </div>
</div>
<div class="p-grid">
  <div class="p-col-4">
    <p-tree [value]="treeNodes" selectionMode="single" [(selection)]="selectedNode"
            (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)"></p-tree>
  </div>
  <div class="p-col-8">
    <div class="p-tree" *ngIf="helpId !== undefined">
      <div class="p-col" style="position: relative">
        <h3 style="margin-top: 0">{{ helpName }}</h3>
        <div style="position: absolute; top: 0; right: 0">ID: {{ helpId }}</div>
      </div>
      <div class="p-col">
        <markdown [data]="helpContent"></markdown>
      </div>
    </div>
    <div class="p-tree" *ngIf="helpId === undefined">
      <div class="p-col">
        <i>Vyberte stránku nápovědy z menu</i>
      </div>
    </div>
  </div>
</div>
